export const topBarHeight = 64;
export const sideNavWidth = 260;
export const navbarHeight = 60;
export const sidenavCompactWidth = 80;
export const containedLayoutWidth = 1200;

export const MyProSideNavWidth = 232;
export const MyProSidenavCompactWidth = 80;

export const DEFAULT_INTEGER = '';
export const DEFAULT_STRING = '';
export const DEFAULT_BOOLEAN = false;
export const DEFAULT_FLOAT = '';
export const DEFAULT_DATE = new Date();
export const DEFAULT_TIME = new Date();
export const DEFAULT_DATETIME = new Date();
export const DEFAULT_TEXT = '';
export const DEFAULT_FILE = null;
export const DEFAULT_OBJECT = {};
export const DEFAULT_MAP = { lat: -6.914744, lng: 107.609810, };

export const RULE_INTEGER_MESSAGE = 'Column required and must be a number';
export const RULE_REQUIRED_MESSAGE = 'Column is Required';
export const RULE_MAX_LENGTH_MESSAGE = 'Column String must be over limit character length';
export const RULE_IS_NUMBER = 'Column must be a number';
export const RULE_MIN_NUMBER = "Column min 1 number"
export const RULE_EMAIL_MESSAGE = "Email not valid";
export const RULE_NUMBER_POSITIVE_MESSAGE = "Must be a positive number";
export const RULE_PHONE_NUMBER_MESSAGE = "Phone number is not valid";

export const PHONE_REGEX = /^$|^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/

export const DELETE_SWAL_OBJECT = {
    title: 'Are you sure?',
    text: "You won't be able to revert this!",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!'
};

export const TEXT_TRUNCATE = {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis'
}

export const JENIS_KELAMIN = [
    { id: 'male', label: 'Laki Laki' },
    { id: 'famale', label: 'Perempuan' },
]
export const MARITAL = [
    { id: 'married', label: 'Menikah' },
    { id: 'single', label: 'Single' },
    { id: 'divorced', label: 'Bercerai' },
]
export const CATEGORY_AGENT = [
    { id: 'fresh', label: 'Fresh Property Consultant' },
    { id: 'senior', label: 'Senior Property Consultant' },
]
export const AKTIF_STATIS = [
    { id: '1', label: 'Aktif' },
    { id: '0', label: 'Non Aktif' },
]
export const ROLES_PENGGUNA = [
    { id: 'SUPER ADMIN', label: 'SUPER ADMIN' },
    { id: 'ADMIN', label: 'ADMIN' },
    { id: 'PRINCIPLE', label: 'PRINCIPLE' },
    { id: 'USER', label: 'USER' },
    { id: 'FINANCE', label: 'FINANCE' },
    { id: 'ACCOUNTING', label: 'ACCOUNTING' },
    { id: 'SALES', label: 'SALES' },
    { id: 'WEB & MEDIA', label: 'WEB & MEDIA' },
    { id: 'MANAGEMENT', label: 'MANAGEMENT' },
]

export const RENT_DURATION_TYPE = [
    { id: 'year', label: 'Tahun' },
    { id: 'month', label: 'Bulan' },
]
export const RENT_TYPE = [
    { id: 'annually', label: 'Per Tahun' },
    { id: 'monthly', label: 'Per Bulan' },
]

export const MARKETING_RIGHT = [
    { 'id': 'exclusive', label: 'Exclusive' },
    { 'id': 'sole', label: 'Sole Agent' },
    { 'id': 'open', label: 'Open' },
]

export const PAP_SELL_TYPE_OPTIONS = [
    { 'label': 'Disewakan', field: 'is_rent' },
    { 'label': 'Dijual', field: 'is_sell' },
    { 'label': 'Dilelang', field: 'is_auction' },
]

export const PAP_IS_PRIMARY_TAB = [
    { label: 'Primary', value: 1 },
    { label: 'Secondary', value: 0 },
]

export const PAP_MASA_BERLAKU_HAK_PEMASARAN = [
    { label: 'Sampai dengan tanggal', val: null },
    { label: 'Sampai terjual / tersewa / informasi lebih lanjut dari pemilik', val: 'unrestricted' },
]

export const PAP_TYPE = [
    { id: 'house', label: 'Rumah' },
    { id: 'land', label: 'Kavling/tanah' },
    { id: 'shop', label: 'Ruko/Rukan' },
    { id: 'kiosk', label: 'Kios' },
    { id: 'apartment', label: 'Apartemen' },
    { id: 'villa', label: 'Villa' },
    { id: 'warehouse', label: 'Gudang' },
    { id: 'office', label: 'Kantor' },
    { id: 'other', label: 'Lainnya' },
]

export const PAP_SERTIFICATE = [
    { id: 'shgb', label: 'SHGB' },
    { id: 'shm', label: 'SHM' },
    { id: 'hak', label: 'Hak Pakai' },
    { id: 'lain', label: 'Lainnya' },
]
export const SPESIFIKAIS = [
    { id: "bed", label: "Kamar Tidur" },
    { id: "bath", label: "Kamar Mandi" },
    { id: "guest", label: "Ruang Tamu" },
    { id: "dining", label: "Ruang Makan" },
    { id: "family", label: "Ruang Keluarga" },
    { id: "kitchen", label: "Dapur" },
    { id: "garden", label: "Teras / Taman" },
    { id: "electicity", label: "Listrik" },
    { id: "telephone", label: "Telepon (Line)" },
    { id: "fence", label: "Pagar" },
    { id: "floor", label: "Lantai" },
    { id: "lounge", label: "Lounge" },
    { id: "water", label: "Air PAM / Sumur" },
    { id: "wtp", label: "WTP" },
    { id: "ac_split", label: "AC Split" },
    { id: "ac_central", label: "AC Central" },
    { id: "watertank", label: "Toren / Tangki Air" },
];

export const FASILITAS = [
    { id: "pool", label: "Kolam Renang" },
    { id: "garage", label: "Garasi" },
    { id: "carport", label: "Carport" },
    { id: "pergola", label: "Pergola" },
];

export const FASILITAS_LINGUNGAN = [
    { id: "pool", label: "Kolam Renang" },
];
